.back-button{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 8px 8px; */
    background-color: #1E4841; 
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 35px;
    height: 35px;
  }
  
  .back-button:hover {
    background-color: #285b52; 
  }
  
  .icon {
    margin-right: 5px; /* Space between icon and text */
  }
  