.authNavbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    /* right: 0; */
    width: 100%;
    z-index: 2;
}