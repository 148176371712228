.backgroud{
    position: absolute;
    top: -3rem;
    left: -7rem;
    background-image: url("../../../data/Group 557.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    height: 80vh;
    width: 100%;
    z-index: 1;
}
.authDashbord-image-section{
    background-image: url("../../../data/Pattern.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;
    z-index: 10;
    margin-top: 4rem;
}
.image-container{
    position: relative;
    margin-top: 5rem;
}
.image2 img{
    position: absolute;
    top: -3rem;
    right: -5rem;
}