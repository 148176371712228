/* report */
.reportContainer,
.leadContainer,
.commisionBoardContainer{
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding-inline: 0.5rem;
    padding-block: 2rem;
    background-color: #F0F5F3;
}
.container{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    /* color: white; */
}
.container .container-menu{
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: #00A762;
    padding: 0.5rem 1rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.sendMail{
    cursor: pointer;
    /* color: #00A762;
    background-color: white; */
    background-color: #1E4841;
    color: white;
    border-radius: 30px;
    padding:0.5rem 1rem;
}
.paid{
    cursor: pointer;
    background-color:white;
    color: #1E4841;
    border-radius: 20px;
    padding:0.5rem 1rem;
}