* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
}
.Childmenuuu {
    width: 280px;
    height: 575px;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    border-radius: 10px;
    margin-left: 1.45%;
    padding: 0rem 2rem;
}

.Childmenuuu > a {
    position: relative;
    top: 10px;
    display: block;
    text-decoration: none;
    width: 100%;
    height: 53px;
    text-align: center;
    float: right;
    margin-top: 10px;
    line-height: 50px;
    transition: color .5s;
    color: #475467;
    border-radius: 15px;
}

.Childmenuuu > a:target,
.Childmenuuu > a.selected {
    color: #1E4841;
}


