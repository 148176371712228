* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
}

.menuuu {
    width: 256px;   
    height: 18%;
    background: linear-gradient(#ffffff, #ffffff);
    font-size: 16px;
    font-weight: 600;
    position: fixed;
    border-radius: 15px;
    /* margin-left: 10px; */
}

.menuuu > a {
    position: relative;
    top: 20px;
    display: block;
    text-decoration: none;
    width: 90%;
    height: 46px;
    text-align: center;
    float: right;
    border-radius: 10px 10px 10px 10px;
    margin-top: 10px;
    line-height: 50px;
    transition: color .5s;
    /* color: #000000; */
}

.menuuu > a:target,
.menuuu > a.selected {
    /* color: #2a2a2a;
    background-color: #F0F5F3; */
}

/* .menuuu > a::after, .menuuu > a::before {
    content: '';
    position: absolute;
    top: -50px;
    right: 0;
    width: 20px;
    height: 50px;
    border-radius: 0 0 0 0;
    z-index: 0;
}

.menuuu > a::before {
    top: auto;
    bottom: -50px;
    border-radius: 0 0px 0 0;
} */

.menuuu > a:target::after,
.menuuu > a.selected::after {
    box-shadow: 0 25px 0 0 #F0F5F3;
}

.menuuu > a:target::before,
.menuuu > a.selected::before {
    box-shadow: 0 -25px 0 0 #F0F5F3;
}
