.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-input-disabled {
  background: none !important;
  cursor: default !important;
  color: #000 !important;
}

.react-calendar {
  width: 100% !important;
}

.ant-timeline-item-head {
  background-color: transparent !important;
  padding-block: 0px !important;
  transform: translate(-50%, -10%) !important;
}
.ant-timeline-item-tail {
  /* background: #00A762 !important; */
}

.ant-switch-checked {
  /* background-color: #00A762 !important; */
}

.removedLeads-container::-webkit-scrollbar {
  width: 0;
}
.removedLeads-container:hover::-webkit-scrollbar {
  width: 5px;
}
.removedLeads-container::-webkit-scrollbar-track {
  background: lightgray;
}

.removedLeads-container::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 10px;
}

.queueLeads-container::-webkit-scrollbar {
  width: 0;
}
.queueLeads-container:hover::-webkit-scrollbar {
  width: 5px;
}
.queueLeads-container::-webkit-scrollbar-track {
  background: lightgray;
}
.queueLeads-container::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 10px;
}

canvas {
  width: 192px !important;
  height: 288px !important;
}

.pdfWrapper > canvas {
  width: 100% !important;
  height: auto !important;
}

.ant-list-item-meta {
  align-items: center !important;
}
.ant-list-item-meta-title {
  margin: 0px !important;
}

/*  */
.my-custom-calendar .react-calendar__month-view__months {
  background-color: #ff6347 !important; 
}

.my-custom-calendar .react-calendar__navigation {
  background-color: #ffffff; 
  color: black; 
  border-radius: 8px; 
}

.my-custom-calendar .react-calendar__navigation button {
  color: black; 
  font-weight: bold;
}

.my-custom-calendar .react-calendar__navigation button:disabled {
  color: rgba(255, 255, 255, 0.5);
}

.custom-calendar .react-calendar__navigation {
  background-color: #ffffff; 
  color: black; 
  border-radius: 8px; 
}

.custom-calendar .react-calendar__navigation button {
  color: black; 
  font-weight: bold;
}